
import React, { useEffect, useState } from 'react';
import {TextStyle} from "@able/react";
import CustomSpacing from "../components/Spacing";
const Loader = ({ title,desc,className }) => {
    const [loadingText,setLoadingText]=useState("Loading...")
    useEffect(() => {
        if (title)
            setLoadingText(title)
    },[title])
    return(
        <div className={`${className ? className : ''} spinner-box`}>
            <CustomSpacing top="spacing10x"/>
            <CustomSpacing top="spacing5x" bottom="spacing5x">
                <div>
                <div className="round-spinner_new">
                    <svg className="t-loader__spinner1" width="30px" height="30px" viewBox="0 0 70 70"
                        xmlns="http://www.w3.org/2000/svg">
                        <circle stroke="#1A5199" strokeWidth="8" className="path" fill="none" cx="35" cy="35" r="30"></circle>
                    </svg>
                    <svg className="t-loader__spinner2" width="30px" height="30px" viewBox="0 0 70 70"
                        xmlns="http://www.w3.org/2000/svg">
                        <circle stroke="#0099F8" strokeWidth="8" className="path" fill="none" cx="35" cy="35" r="30"></circle>
                    </svg>
                    <svg className="t-loader__spinner3" width="30px" height="30px" viewBox="0 0 70 70"
                        xmlns="http://www.w3.org/2000/svg">
                        <circle stroke="#1A5199" strokeWidth="8" className="path" fill="none" cx="35" cy="35" r="30"></circle>
                    </svg>
                </div>
                <CustomSpacing top="spacing3x"/>
                <TextStyle role="status" alias="HeadingB">{loadingText}</TextStyle>
                <CustomSpacing top="spacing2x"/>
                { desc ?
                    <TextStyle role="status">{desc}</TextStyle> : 
                    <TextStyle role="status">
                        This may take up to 10 seconds.<br/>Please don't close the window.
                    </TextStyle>
                }
                </div>
            </CustomSpacing>
        </div>
    )
}

export default Loader;
import React, { useState, useEffect, useRef } from "react";
import {
  Grid,
  Column,
  InlineLink,
  TextStyle,
  Select,
  ActionButton,
  Checkbox,
  TextField,
  DayMonthYearGroup,
  RadioButton,
  RadioGroup,
  ModalContent,
  useToggle,
  MessageInline,
  ModalDialogCriticalDecision
} from "@able/react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PageContent from "../PageContent";
import Loader from "../../components/Loader";
import PageTitle from "../../components/PageTitle";
import { updateAnalyticsData } from '../../helpers/analytics';
import { setSession, getSession } from "../../helpers";
import idSelection from "../../helpers/constants.json";
import getSampleImage from "../../helpers/sampleImage";
import validations from "../../helpers/validations";
import importInputKeys from "../../components/importIDkeys";
import { validateIDVDocument } from '../../redux/api';
import { serverErrors, errorRefId } from "../../helpers/messages";
import { saveIDVDocResponse, setCommonError } from '../../redux/verifyIdentifySlice';
import CustomSpacing from "../../components/Spacing";
import GlobalBanner from "../../components/GlobalBanner";
import { dvsResponses } from "../../helpers/idv_messages";

const editInputs = {
  firstName: "Given name",
  middleName: "Middle name/s",
  familyName: "Family name",
  dob: "Date of birth",
}
const privacy_link = "https://www.telstra.com.au/privacy#info-collect";
const request_change_link = "https://www.telstra.com.au/forms/change-of-name-request";
const telstra_store_link = "https://www.telstra.com.au/find-us";

export default function PageVerifyIdentity() {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const titleRef = useRef(null);
  const checkboxRef = useRef(null);
  const [checkbox, setCheckbox] = useState(false);
  const [checkboxErr, setCheckboxErr] = useState(false);
  const radioGroupRef = useRef(null);
  const inputRef = useRef({});
  const buttonRef = useRef({});
  const idUnavialableRef = useRef(null);
  const [modalIsOpen, toggleModal] = useToggle();
  const [isEditModel, toggleEditModal] = useToggle();
  const [removeModalIsOpen, setRemoveModal] = useToggle();
  const currentYear = new Date().getFullYear();
  const [expiryDateMinYear, setExpiryDateMinYear] = useState(currentYear);
  const [title] = useState("Verify your identity");
  const [isLoading, setIsLoading] = useState(true);
  const [isSingleName, setIsSingleName] = useState(false);
  const [profileInputs, setProfileInputs] = useState(idSelection.profile);
  const [selectIdType, setSelectIdType] = useState("");
  const [documentKeys, setDocumentKeys] = useState([]);
  const [documentInputs, setDocumentInputs] = useState({});
  const [dlHelpText, setDLHelpText] = useState({documentNumber:'',cardNumber:''});
  const [cardImages, setCardImages] = useState({title:'',data:[],type:null});
  const [colorCode,setColorCode] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState({day:"",month:"",year:""});
  const [expiryDate, setExpiryDate] = useState({day:"",month:"",year:""});
  const [errors, setErrors] = useState({});
  const [focusErrElement, setFocusErrElement] = useState(false);
  const [isNameUpdated, setIsNameUpdated] = useState({ status: false,icon: "Success" });
  const [editbleName, setEditableName] = useState({ key: "",label:'', title: "", value: "", helpText:"" });
  const [isAuthenticated, setIsAuthenticated] = useState(getSession("isAuthenticated"));
  const [isProfileEdited, setIsProfileEdited] = useState(false);
  const [dvsAttempts, setDvsAttempts] = useState({ status: false, text: "" });
  const [dvsCalls, setDvsCalls] = useState(3);
  const { validateIDVResponse, isPageLoaded, IDVDocResponse } = useSelector((state) => state.verifyIdentify);
  const [disableDate, setDisableDate] = useState(false);
  const [dvsAttemptCount, setDvsAttemptCount] = useState(1);
  const [dvsErrResponse, setDvsErrResponse] = useState({});
  let isBlurFired = false;

  useEffect(() => {
    if (!getSession("dvsCalls") || getSession("dvsCalls") === 0) {
      setSession("dvsCalls", 3)
      setDvsCalls(3)
      setDvsAttemptCount(1)
    } else {
      setSession("dvsCalls", getSession("dvsCalls"))
      setDvsCalls(getSession("dvsCalls"))
      setDvsAttemptCount(parseInt(getSession("dvsCalls") - 2))
    }
    if (isPageLoaded) {
      navigation("/")
    }
  }, [])

  // updating analytics on every event within the document
  const updateAnalyticsDataFunc = (eventType,eventCategory,eventName) => {
    updateAnalyticsData(title, null, {
      "eventInfo": {
        "eventAction": "error",
        "eventType": eventType,
        "eventCategory": eventCategory,
        "eventName": eventName
      },
      "attributes": {}
    })
  }

  const removeSpaceToLowercase = (str) => {
    let value = str ? str : "";
    return value.replace(/\s/g, '').split(".").join("").toLowerCase();
  }

  const callDVSAPI = () => {
    setIsLoading(true);
    let verificationId = {}
    if (Object.keys(IDVDocResponse).length > 0 || validateIDVResponse.verificationId) {
      verificationId = {
        verificationId: IDVDocResponse.verificationId || validateIDVResponse.verificationId,
      }
    }
    const payload = {
      "idvInfoInput": {
        "personalInfoInput": {
          ...profileInputs,
          ...verificationId,
          counter: getSession("dvsCalls") - 1,
          scanType: "Digital",
          referenceId: validateIDVResponse.referenceId,
          clientCode: validateIDVResponse.clientCode
        },
        "documentInfoInput": [
          {
            idType: selectIdType,
            ...documentInputs,
          }
        ]
      }
    };
    validateIDVDocument(payload)(dispatch)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.overallResult === "REJECT") {
            dispatch(saveIDVDocResponse(response.data))
            if (getSession("dvsCalls") === 1 || dvsCalls === 1) {
              navigation("/error")
            } else {
              setDvsAttempts(prevState => ({ ...prevState, status: true }));
              setSession("dvsCalls", getSession("dvsCalls") - 1);
              setDvsCalls(dvsCalls - 1)
              setDvsAttemptCount(dvsAttemptCount - 1);
              setIsLoading(false);
              if(isAuthenticated){
                setIsProfileEdited(false);
                const { personalDetails } = validateIDVResponse;
                setProfileInputs(prevState => ({
                  ...prevState,
                  ...personalDetails
                }))
              }
              const errorData = dvsResponses.find((el) =>
                removeSpaceToLowercase(response.data.rejectReason) === removeSpaceToLowercase(el.errorType) &&
                removeSpaceToLowercase(response.data.documentType) === removeSpaceToLowercase(el.documentType)
              );
              const defaultErr = dvsResponses.find((el) => el.errorType === "default");
              const dvsError = errorData || defaultErr;
              setDvsErrResponse({
                title: dvsError.attempts[dvsAttemptCount].title,
                content: dvsError.attempts[dvsAttemptCount].content,
                count: dvsError.attempts[dvsAttemptCount].count
              })
              const errTxt = `${dvsError.attempts[dvsAttemptCount].title}, ${dvsError.attempts[dvsAttemptCount].content}, ${dvsError.attempts[dvsAttemptCount].count}`;
              updateAnalyticsDataFunc("Technical","attempts_err", errTxt);
              window.scrollTo(0, 0);
            }
          } else {
            dispatch(saveIDVDocResponse(response.data))
            setSession("dvsCalls", 3)
            setDvsCalls(3)
            if (getSession("gotoUrl")) window.location = getSession("gotoUrl")
            else navigation("/confirm-identity");
          }
        } else {
          const eKay = "validateIDVDocument";
          const err = "err_" + response.status + (response.errors ? "_" + response.errors[0].code : "");
          const refCode = errorRefId.prefix + errorRefId.delimeter + errorRefId[eKay] + serverErrors[eKay][err].errRefNo;
          dispatch(setCommonError({ ...serverErrors[eKay][err], refCode }))
          updateAnalyticsDataFunc("Technical",err, serverErrors[eKay][err].errDesc)
          if (serverErrors[eKay][err] && (serverErrors[eKay][err].code === "err_422_2100" ||
            serverErrors[eKay][err].redirectTo === "gotoUrl")) {
            setTimeout(() => {
              window.location = getSession("gotoUrl");
            }, 5000);
          } else {
            navigation("/error");
          }
        }
      })
      .catch((error) => {
        navigation("/error")
      });
  }

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false)
    }, 2000);
  },[])

  // handling click events
  const handleOnClick = (id, eventName, editName) => {
    let eventAction = id === "edit" ? "impression" : "click"
    let eventType = id === "edit" ? "modelContent" : "clickTrack"
    let eventCategory = id === "edit" ? "Information" : "buttonClick"
    updateAnalyticsData(title, null, {
      "eventInfo": {
        "eventAction": eventAction,
        "eventType": eventType,
        "eventCategory": eventCategory,
        "eventName": eventName
      },
      "attributes": {}
    })
    if (id === "find" || id === "close") {
      if (id === "close"){
        buttonRef && buttonRef.current && buttonRef.current["findMyID"].focus();
      }
      if(id==="find"){
        const idCard = idSelection.idType.find((map)=>{return map.label===selectIdType});
        if(idCard!=null){
          const imageData = {
            title:idCard.id=='DL' ? documentInputs.stateOfIssue+" Driver License" : 
            ((idCard.id=='HMEDICARD' && documentInputs.cardColor!='') ? idSelection[idCard.id].color[documentInputs.cardColor]  : selectIdType),
            data:idSelection[idCard.id],
            type:idCard.id=='DL' ? documentInputs[idSelection[idCard.id].key] : idCard.id 
          }
          setCardImages(imageData);
        }
        window.scrollTo(0,0)
      }
      toggleModal();
    }
    if (id === "agree") {
      if (!validateIDDetails()) return
      if (!checkbox) {
        updateAnalyticsDataFunc("inline", "Consent_required", "Please provide consent to verify your ID")
        setCheckboxErr(true);
        checkboxRef.current.focus();
        return
      }
      setIsNameUpdated(prevState=>({
        ...prevState,
        status:false
      }));
      setDvsAttempts(prevState => ({ ...prevState, status: false }));
      callDVSAPI()
    }
    if (id === "verify") {
      setTimeout(() => {
        navigation("/confirm-identity")
      },500)
    }
    if (id === "cancelChangeOfName") {
      const { personalDetails } = validateIDVResponse;
      setProfileInputs(prevState => ({
        ...prevState,
        ...personalDetails
      }))
      setIsProfileEdited(false);
      setIsNameUpdated(prevState=>({
        ...prevState,
        status:false
      }))
    }
    if (id === "edit") {
      window.scrollTo(0,0)
      toggleEditModal();
    }
    if (id === "edit" || id === "remove") {
      window.scrollTo(0, 0);
      if (id === "remove") setRemoveModal()
      const contentTitle = (isSingleName && editName!=='dob') ? 'Name' : editInputs[editName];
      let editableTitle = `Edit ${contentTitle}`;
      let infoText = isSingleName ? 'Enter your name as it appears on your ID, including spaces, apostrophes, or hyphens.' :  'Enter your details exactly as they appear on your ID, including spaces, apostrophes, or hyphens.'
      if (editName === "middleName") {
        editableTitle = sanitizeValue(profileInputs.middleName) ? `Edit ${contentTitle}` : `Add ${contentTitle}`
        infoText = "Include all middle names as they appear on your ID. For example, Aaron might be written as A on your ID.";
      }
      setEditableName({ key: editName, label:contentTitle, title: editableTitle, value: profileInputs[editName], helpText:infoText });
    }
    if (id === "removeMN") {
      setProfileInputs(prevState => ({
        ...prevState,
        middleName: ""
      }))
      setIsNameUpdated(prevState => ({
        ...prevState,
        status: true,
        icon: "Success"
      }));
    }
  }

  useEffect(() => {
    const { personalDetails } = validateIDVResponse;
    const authenicatedFlag = (personalDetails && Object.keys(personalDetails).length > 0)
    if(validateIDVResponse.isSingleName && validateIDVResponse.isSingleName!="false"){
      setIsSingleName(true);
      let keys = idSelection.mononymous;
      setProfileInputs(keys);
    }
    setIsAuthenticated(authenicatedFlag)
    if (!authenicatedFlag) {
      let keys = {};
      if (isSingleName) {
        keys = idSelection.mononymous;
      }
      if (!isSingleName) {
        keys = idSelection.profile;
      }
      setProfileInputs(keys)
    }
    if (authenicatedFlag) {
      if(!isSingleName){
        setProfileInputs({ ...personalDetails})
      }else{
        setProfileInputs(prevState => ({
          ...prevState,
          firstName: personalDetails.firstName,
          dob:personalDetails.dob
        }));
      }

    }
  }, [isSingleName])


  const handleOnChange = (e, type, id) => {
    const { name, value } = e.target;
    const newValue = name === "idType" ? idSelection.idTypeLabel[value] : value;
    if (name === "consent") {
      if (e.target.checked) {
        updateAnalyticsData(title, null, {
          "eventInfo": {
            "eventAction": "click",
            "eventType": "clickTrack",
            "eventCategory": "buttonClick",
            "eventName": "I agree that my identity document details will be checked with the official record holder via third party systems to verify my identity"
          },
          "attributes": {}
        })
      }
      else updateAnalyticsDataFunc("inline", "Consent_required", "Please provide consent to verify your ID")
      
      setCheckbox(e.target.checked)
      setCheckboxErr(!e.target.checked)
    }
    if (name === "idType") {
      const attention = `Verifying with ${newValue} is temporarily unavailable. Please choose other ID types or visit your nearest`
      setColorCode("");
      setDocumentKeys(importInputKeys(value))
      let data = idSelection[value];
      if (value === "AUSTRALIAN_PASSPORT" || value=="INTERNATIONAL_PASSPORT") {
        data["issuingCountry"] = value === "AUSTRALIAN_PASSPORT" ? "AUS" : "";
        setExpiryDateMinYear(currentYear-3);
      } else {
        setExpiryDateMinYear(currentYear);
        delete data["issuingCountry"];
      }
      setExpiryDate({ day: "", month: "", year: "" })
      setDocumentInputs(data)
      setErrors({})
      setDisableDate(false);
    }
    if(name=='stateOfIssue'){
        const selectedState = idSelection.stateList.find((map)=>{return map.value===value});
        if(selectedState!=null){
          setDLHelpText(selectedState.helpText);
        }else{
          setDLHelpText({documentNumber:'',cardNumber:''});
        }
    }
    if (name == 'cardColor') {
      setColorCode(value);
      if (value=='G') {
        setDisableDate(true)
      } else {
        setDisableDate(false);
      }
      setExpiryDate({ day: "", month: "", year: "" });
      setErrors(prevState => ({
        ...prevState,
        ['expiryDate']: undefined
      }));
    }
    if (type === "checkbox") {
      updateAnalyticsData(title, null, {
        "eventInfo": {
          "eventAction": "click",
          "eventType": "clickTrack",
          "eventCategory": "buttonClick",
          "eventName": id
        },
        "attributes": {}
      })
      setIsSingleName(e.target.checked)
      setSelectIdType("")
      setDocumentKeys([])
      setExpiryDate({ day: "", month: "", year: "" })
      setDateOfBirth({ day: "", month: "", year: "" })
    } else {
      if (id === "idType") {
        setSelectIdType(newValue)
      } else if (id === "profile") {
        setProfileInputs(prevState => ({
          ...prevState,
          [name]: newValue
        }))
      } else {
          setDocumentInputs(prevState => ({
            ...prevState,
            [name]: newValue
          }))
      }
    }
  }

  const handleOnBlur = (e, type, id) => {
    e.preventDefault();
    if (isBlurFired) return
    isBlurFired = true
    if (focusErrElement) setFocusErrElement(false)
    let { name, value } = e.target;
    value = name === "idType" ? idSelection.idTypeLabel[value] : value
    let error = {};
    if (id === "profile") {
      error = validations(name, value, profileInputs, isSingleName)
    } else {
      error = validations(name, value, documentInputs, selectIdType)
    }
    error && error.msg && updateAnalyticsDataFunc("inline",error.code,error.msg)
    if (error && error.msg && window.digitalData) {
      setErrors(prevState => ({
        ...prevState,
        [name]: error.msg
      }))
    } else {
      setErrors(prevState => ({
        ...prevState,
        [name]: undefined
      }));
    }
  }

  const validateIDDetails = () => {
    setFocusErrElement(true)
    let isValid = false;
    let data = {};
    !isAuthenticated && Object.keys(profileInputs).forEach(key => {
      const error = validations(key, profileInputs[key], profileInputs)
      data[key] = error && error.msg
      error && error.msg && updateAnalyticsDataFunc("Inline",error.code,error.msg)
    })
    if (selectIdType.trim() === "") {
      const error = validations("idType", selectIdType)
      data["idType"] = error && error.msg
      error && error.msg && updateAnalyticsDataFunc("Inline",error.code,error.msg)
    }
    Object.keys(documentInputs).length > 0 && Object.keys(documentInputs).forEach(key => {
      const error = validations(key, documentInputs[key], documentInputs, selectIdType)
      data[key] = error && error.msg
      error && error.msg && updateAnalyticsDataFunc("Inline",error.code,error.msg)
    })
    if (profileInputs.hasOwnProperty("middleName") && profileInputs["middleName"] === "" && profileInputs["middleName"].trim() === "") {
      delete data["middleName"]
    }
    Object.keys(data).forEach(key => {
      if (!data[key]) {
        delete data[key];
      }
    })
    setErrors(data)
    if (Object.keys(data).length === 0) isValid = true
    return isValid
  }

  useEffect(() => {
    if (focusErrElement) {
      if (Object.keys(errors).length > 0) {
        let controlObj = { ...errors };
        Object.keys(controlObj).forEach(
          (key) => (controlObj[key] === undefined || controlObj[key] === null) && delete controlObj[key]
        );
        if (Object.keys(controlObj).length > 0) {
          inputRef.current &&
            inputRef.current[Object.keys(controlObj)[0]].focus();
          setFocusErrElement(false);
        }
      }
    }
  }, [errors, focusErrElement]);

  const FooterContent = () => {
    return (
      <CustomSpacing top="spacing2x">
        <ActionButton
          label='Got it'
          variant='HighEmphasis'
          element='button'
          className="fullwidth"
          onClick={() =>
            handleOnClick("close", "Got it")
          }
          developmentUrl={window.location.origin + "/able-sprites.svg"}
        />
      </CustomSpacing>
    );
  };

  const customDateChange = (value, name, id) => {
    if (focusErrElement) setFocusErrElement(false)
    const today = new Date();
    const medicareDay = today.getDate().toString().length === 1 ? "0"+today.getDate().toString() : today.getDate().toString();
    const day = value.day && value.day.length === 1 ? +"0" + value.day : value.day;
    const month = value.month && value.month.length === 1 ? +"0" + value.month : (value.month ? value.month :"");
    const year = value.year ? value.year : "";
    let newDay = documentInputs.cardColor && documentInputs.cardColor === "G" ? medicareDay : day
    const dateValue = newDay + "/" + month + "/" + year;
    let error = {};
    if (name === "dob") {
      setDateOfBirth(value);
    } else {
      if(documentInputs.cardColor && documentInputs.cardColor === "G"){
        setExpiryDate({...value,day:newDay})
      }else{
        setExpiryDate(value);
      }
    }
    if (name === "dob") {
      setProfileInputs(prevState => ({
        ...prevState,
        [name]: dateValue
      }))
    } else {
      setDocumentInputs(prevState => ({
        ...prevState,
        [name]: dateValue
      }))
    }
    if (id === "profile") {
      error = validations(name, dateValue, profileInputs)
    } else {
      error = validations(name, dateValue, documentInputs, selectIdType)
    }
    if (error && error.msg && window.digitalData) {
      setErrors(prevState => ({
        ...prevState,
        [name]: error.msg
      }))
      // updateAnalyticsDataFunc("inline",error.code,error.msg)
    } else {
      setErrors(prevState => ({
        ...prevState,
        [name]: undefined
      }))
    }
  };

  const customDateBlur = (value, name, id) => {
    const today = new Date();
    const day = value.day && value.day.length === 1 ? +"0" + value.day : value.day;
    const month = value.month && value.month.length === 1 ? +"0" + value.month : (value.month ? value.month :"");
    const year = value.year ? value.year : "";
    const medicareDay = today.getDate().length === 1 ? "0"+today.getDate() : today.getDate();
    let newDay = documentInputs.cardColor && documentInputs.cardColor === "G" ? medicareDay : day
    const dateValue = newDay + "/" + month + "/" + year;  
    let error = {}
    if (id === "profile") {
      error = validations(name, dateValue, profileInputs)
    } else {
      error = validations(name, dateValue, documentInputs, selectIdType)
    }
    if (error && error.msg && window.digitalData) {
      updateAnalyticsDataFunc("inline",error.code,error.msg)
    }
  };

  const checkInvalidDate = (date)=>{
    let dateVal = date.split("/");
    return dateVal[0]==='' || dateVal[1]==='' || dateVal[2]==='';
  }

  const modalContentOnClose = (id,eventName) => {
    updateAnalyticsData(title, null, {
      "eventInfo": {
        "eventAction": "click",
        "eventType": "clickTrack",
        "eventCategory": "buttonClick",
        "eventName": eventName
      },
      "attributes": {}
    })
    if (id === "findMyID"){ 
      buttonRef && buttonRef.current && buttonRef.current[id].focus();
    } else{ 
      buttonRef && buttonRef.current && buttonRef.current[editbleName.key].focus();
    }
  }

  const BodyContentEdit = () => {
    const [value, setValue] = useState(editbleName.value.trim());
    const [err, setErr] = useState(null);
    const events = {
      onChange: (e) => {
        setValue(e.target.value);
      },
      onBlur: (e) => {
        let error = validations(editbleName.key, value)
        if (error && error.msg) {
          setErr(error.msg);
          updateAnalyticsDataFunc("inline",error.code,error.msg)
        } else {
          setErr(null)
        }
      },
    };

    const handleOnModelClick = (id, eventName) => {
      updateAnalyticsData(title, null, {
        "eventInfo": {
          "eventAction": "click",
          "eventType": "clickTrack",
          "eventCategory": "buttonClick",
          "eventName": editbleName.title + " - " + eventName
        },
        "attributes": {}
      })

      if (id === "cancel") {
        toggleEditModal();
        buttonRef && buttonRef.current[editbleName.key].focus();
      } else {
        if (profileInputs[editbleName.key].trim() !== value && value.trim().length > 0) {
          let error = validations(editbleName.key, value)
          if (error && error.msg) {
            setErr(error.msg)
            updateAnalyticsDataFunc("inline",error.code,error.msg)
            return
          }
          setProfileInputs(prevState => ({
            ...prevState,
            [editbleName.key]: value
          }))
          if(editbleName.key!=='middleName'){
            setIsProfileEdited(true)
          }
          setIsNameUpdated(prevState => ({
            ...prevState,
            status: true,
            icon: "Success"
          }));
          setDvsAttempts(prevState => ({ ...prevState, status: false }));
        }
        toggleEditModal();
      }
    };

    return (
      <React.Fragment>
        <TextStyle>{editbleName.helpText}</TextStyle>
        <CustomSpacing top="spacing3x">
          <TextField
            id={editbleName.key}
            name={editbleName.key}
            label={editbleName.label}
            value={value}
            invalid={err ? true : false}
            invalidInputText={err}
            developmentUrl={window.location.origin + "/able-sprites.svg"}
            events={events}
            ref={e => inputRef.current[editbleName.key] = e}
          />
        </CustomSpacing>
        <TextStyle className="able-ModalContent--Compact_footer">
          <ActionButton
            label='Cancel'
            variant='MediumEmphasis'
            element='button'
            onClick={() =>
              handleOnModelClick("cancel", "Cancel")
            }
            developmentUrl={window.location.origin + "/able-sprites.svg"}
          />
          <ActionButton
            label='OK'
            variant='HighEmphasis'
            element='button'
            onClick={() =>
              handleOnModelClick("ok", "OK")
            }
            developmentUrl={window.location.origin + "/able-sprites.svg"}
          />
        </TextStyle>
      </React.Fragment>
    );
  };

  const sanitizeValue = (value) => {
    return !validations("isEmpty",value)
  }

  return (
    <PageContent title={title}>
      {isLoading ? <Loader />
        :
        <Grid padding={false}>
          <Column cols={12}>
            <ModalContent
              variant="Compact"
              title={cardImages.title}
              bodyContent={getSampleImage(cardImages, colorCode)}
              footerContent={<FooterContent />}
              isShowing={modalIsOpen}
              setHideDialog={toggleModal}
              className="able-ModalContent able-sampleCard"
              onClose={() => modalContentOnClose("findMyID","Sample image modal - close")}
              developmentUrl={window.location.origin + "/able-sprites.svg"}
            />
            <ModalContent
              variant="Compact"
              title={editbleName.title}
              bodyContent={<BodyContentEdit />}
              footerContent={false}
              isShowing={isEditModel}
              setHideDialog={toggleEditModal}
              className="able-ModalContent able-editbleName"
              onClose={() => modalContentOnClose("editModel","Edit modal - close")}
              developmentUrl={window.location.origin + "/able-sprites.svg"}
            />
            <ModalDialogCriticalDecision
              isShowing={removeModalIsOpen}
              className="able-fonts"
              setHideDialog={setRemoveModal}
              title='Remove middle name?'
              description='Are you sure you want to remove your middle name?'
              stackButtonOnVXS={false}
              preferredButtonLabel='Yes'
              preferredButtonEvents={{ onClick: () => handleOnClick("removeMN", "Remove middle name - Yes") }}
              secondaryButtonLabel='Cancel'
              secondaryButtonEvents={{ onClick: () => handleOnClick("cancelMN", "Remove middle name - Cancel") }}
              developmentUrl={window.location.origin + "/able-sprites.svg"}
              focusOnCloseRef={buttonRef.current[editbleName.key]}
            />
            <PageTitle ref={titleRef} title={title} focusTitle={false} bottom="spacing3x" />
            <TextStyle alias="HeadingC" element="h2">Your ID details</TextStyle>
            <CustomSpacing top="spacing1x">
              <TextStyle>
              To ensure we can verify your identity accurately, please {isAuthenticated ? "check" : "enter"} your full name, including any middle names, exactly as they appear on your ID.
              </TextStyle>
              <CustomSpacing top="spacing1x">
                <TextStyle>
                  For example, you might enter John Aaron Smith or John A Smith, depending on what's shown on your ID.
                </TextStyle>
              </CustomSpacing>
            </CustomSpacing>
            { isAuthenticated ?
              <CustomSpacing top="spacing2x">
                <CustomSpacing className="authenticated_bg" top="spacing2x" bottom="spacing2x" left="spacing3x" right="spacing3x">
                  <InlineLink>
                    <TextStyle>
                        Have both your given name and family name changed? You can <a className="cnsbColor" rel="noreferrer" href={request_change_link} target="_blank">request change of name</a> or <a className="cnsbColor" rel="noreferrer" href={telstra_store_link} target="_blank">visit your nearest Telstra store</a> for assistance.
                    </TextStyle>
                  </InlineLink>
                </CustomSpacing>
              </CustomSpacing>
              : null
            }
            <div role="alert">
              {dvsAttempts.status ?
                <CustomSpacing top="spacing3x">
                  <MessageInline developmentUrl={window.location.origin + '/able-sprites.svg'} variant="Error">
                    <TextStyle alias="LabelA1" element="p">{ dvsErrResponse.title }</TextStyle>
                    <TextStyle element="p">{ dvsErrResponse.content }</TextStyle>
                    <CustomSpacing top="spacing1x"/>
                    <TextStyle element="p">{ dvsErrResponse.count }</TextStyle>
                  </MessageInline>
                </CustomSpacing>
                : null
              }
            </div>
            <div role="alert">
            {
              isNameUpdated.status ?
                <CustomSpacing top="spacing3x">
                  <MessageInline
                    variant={isNameUpdated.icon}
                    developmentUrl={
                      window.location.origin + "/able-sprites.svg"
                    }
                  >
                    <TextStyle element="p">Your details will be updated to your account once your ID is verified in the next step. </TextStyle>
                    <CustomSpacing top="spacing1x"/>
                    <TextStyle element="p">You can still cancel this change request. Your ID will be verified with your existing details in our system.</TextStyle>
                    <CustomSpacing top="spacing1x" />
                    <InlineLink>
                      <TextStyle element="p">
                        <a className="cnsbColor" rel="noreferrer" onClick={() => handleOnClick("cancelChangeOfName", "Cancel change request")}>Cancel change request</a>
                      </TextStyle>
                    </InlineLink>
                  </MessageInline>
                </CustomSpacing>
                : null
              }
            </div>
            {
              isAuthenticated ?
                <React.Fragment>
                  {isSingleName ?
                      <CustomSpacing top="spacing3x">
                      <CustomSpacing bottom="spacing1x">
                        <TextStyle alias="LabelA1">Name</TextStyle>
                      </CustomSpacing>
                      <TextStyle>{profileInputs["firstName"]}</TextStyle>
                      {
                        (!isProfileEdited && validateIDVResponse.pdEditable) ?
                          <ActionButton
                            developmentUrl={
                              window.location.origin + "/able-sprites.svg"
                            }
                            element="button"
                            label="Edit name"
                            to="/"
                            icon="Edit"
                            type="button"
                            variant="LowEmphasis"
                            onClick={() =>
                              handleOnClick("edit", "Edit name", "firstName")
                            }
                            elementRef={e => buttonRef.current["firstName"] = e}
                          /> : null
                      }
                    </CustomSpacing>
                  : 
                    <CustomSpacing>
                      <CustomSpacing top="spacing3x">
                        <CustomSpacing bottom="spacing1x">
                          <TextStyle alias="LabelA1">Given name</TextStyle>
                        </CustomSpacing>
                        <TextStyle>{profileInputs["firstName"]}</TextStyle>
                        {
                          (!isProfileEdited && validateIDVResponse.pdEditable) ?
                            <ActionButton
                              developmentUrl={
                                window.location.origin + "/able-sprites.svg"
                              }
                              element="button"
                              label="Edit Given name"
                              to="/"
                              icon="Edit"
                              type="button"
                              variant="LowEmphasis"
                              onClick={() =>
                                handleOnClick("edit", "Edit Given name", "firstName")
                              }
                              elementRef={e => buttonRef.current["firstName"] = e}
                            /> : null
                        }
                      </CustomSpacing>
                      <CustomSpacing top="spacing3x">
                        <TextStyle alias="LabelA1">Middle name/s (if present)</TextStyle>
                        <CustomSpacing bottom="spacing1x">
                          <TextStyle alias="FinePrintA" className="finePrintA-color">Include all middle names as they appear on your ID. For example, Aaron might be written as A on your ID.</TextStyle>
                        </CustomSpacing>
                        <TextStyle>{profileInputs["middleName"]}</TextStyle>
                        <TextStyle element="div" className="buttonGroup">
                          {
                            validateIDVResponse.pdEditable ?
                              <React.Fragment>
                                <ActionButton
                                  developmentUrl={
                                    window.location.origin + "/able-sprites.svg"
                                  }
                                  element="button"
                                  label={`${sanitizeValue(profileInputs.middleName) ? "Edit Middle name" : "Add Middle name"}`}
                                  icon="Edit"
                                  type="button"
                                  variant="LowEmphasis"
                                  onClick={() =>
                                    handleOnClick("edit", `${sanitizeValue(profileInputs.middleName) ? "Edit Middle name" : "Add Middle name"}`, "middleName")
                                  }
                                  elementRef={e => buttonRef.current["middleName"] = e}
                                />
                                {
                                  sanitizeValue(profileInputs.middleName) &&
                                  <ActionButton
                                    developmentUrl={
                                      window.location.origin + "/able-sprites.svg"
                                    }
                                    element="button"
                                    label="Remove"
                                    icon="Delete"
                                    type="button"
                                    variant="LowEmphasis"
                                    className="able-ActionButton--destructive"
                                    onClick={() =>
                                      handleOnClick("remove", "Remove", "middleName")
                                    }
                                    elementRef={e => buttonRef.current["middleName"] = e}
                                  />
                                }
                              </React.Fragment> : null
                          }
                        </TextStyle>
                      </CustomSpacing>
                      <CustomSpacing top="spacing3x">
                        <CustomSpacing bottom="spacing1x">
                          <TextStyle alias="LabelA1">Family name</TextStyle>
                        </CustomSpacing>
                        <TextStyle>{profileInputs["familyName"]}</TextStyle>
                        {
                          (!isProfileEdited && validateIDVResponse.pdEditable) ?
                            <ActionButton
                              developmentUrl={
                                window.location.origin + "/able-sprites.svg"
                              }
                              element="button"
                              label="Edit Family name"
                              to="/"
                              icon="Edit"
                              type="button"
                              variant="LowEmphasis"
                              onClick={() =>
                                handleOnClick("edit", "Edit Family name", "familyName")
                              }
                              elementRef={e => buttonRef.current["familyName"] = e}
                            /> : null
                        }
                      </CustomSpacing>
                    </CustomSpacing>
                  }
                  <CustomSpacing top="spacing3x">
                    <CustomSpacing bottom="spacing1x">
                      <TextStyle alias="LabelA1">Date of birth</TextStyle>
                    </CustomSpacing>
                    <TextStyle>{profileInputs["dob"]}</TextStyle>
                    {
                      (!isProfileEdited && validateIDVResponse.dobEditable) ?
                        <ActionButton
                          developmentUrl={
                            window.location.origin + "/able-sprites.svg"
                          }
                          element="button"
                          label="Edit Date of birth"
                          to="/"
                          icon="Edit"
                          type="button"
                          variant="LowEmphasis"
                          onClick={() =>
                            handleOnClick("edit", "Edit Date of birth", "dob")
                          }
                          elementRef={e => buttonRef.current["dob"] = e}
                        /> : null
                    }
                  </CustomSpacing>
                </React.Fragment>
                :
                <React.Fragment>
                  <CustomSpacing top="spacing3x" bottom="spacing1x">
                    <Checkbox
                      label="I have a single name (mononyms) on my ID"
                      name="singleName"
                      value="singleName"
                      checked={isSingleName}
                      events={{
                        onChange: (e) => handleOnChange(e, "checkbox","I have a single name (mononyms) on my ID")
                      }}
                    />
                  </CustomSpacing>
                  {
                    isSingleName ?
                      <CustomSpacing bottom="spacing3x">
                        <TextField
                          id="firstName"
                          name="firstName"
                          label="Name"
                          value={profileInputs.firstName}
                          helpText="Enter your name as it appears on your ID, including spaces, apostrophes, or hyphens."
                          invalid={errors.firstName ? true : false}
                          invalidInputText={errors.firstName}
                          developmentUrl={window.location.origin + "/able-sprites.svg"}
                          onChange={(e) => handleOnChange(e, "text", "profile")}
                          onBlur={(e) => handleOnBlur(e, "text", "profile")}
                          ref={e => inputRef.current["firstName"] = e}
                        />
                      </CustomSpacing>
                      :
                      <React.Fragment>
                        <CustomSpacing bottom="spacing3x">
                          <TextField
                            id="firstName"
                            name="firstName"
                            label="Given name"
                            value={profileInputs.firstName}
                            invalid={errors.firstName ? true : false}
                            invalidInputText={errors.firstName}
                            developmentUrl={window.location.origin + "/able-sprites.svg"}
                            onChange={(e) => handleOnChange(e, "text", "profile")}
                            onBlur={(e) => handleOnBlur(e, "text", "profile")}
                            ref={e => inputRef.current["firstName"] = e}
                          />
                        </CustomSpacing>
                        <CustomSpacing bottom="spacing3x">
                          <TextField
                            id="middleName"
                            name="middleName"
                            label="Middle name/s (if present)"
                            helpText="Include all middle names as they appear on your ID. For example, Aaron might be written as A on your ID."
                            value={profileInputs.middleName}
                            invalid={errors.middleName ? true : false}
                            invalidInputText={errors.middleName}
                            developmentUrl={window.location.origin + "/able-sprites.svg"}
                            onChange={(e) => handleOnChange(e, "text", "profile")}
                            onBlur={(e) => handleOnBlur(e, "text", "profile")}
                            ref={e => inputRef.current["middleName"] = e}
                          />
                        </CustomSpacing>
                        <CustomSpacing bottom="spacing3x">
                          <TextField
                            id="familyName"
                            name="familyName"
                            label="Family name"
                            value={profileInputs.familyName}
                            invalid={errors.familyName ? true : false}
                            invalidInputText={errors.familyName}
                            developmentUrl={window.location.origin + "/able-sprites.svg"}
                            onChange={(e) => handleOnChange(e, "text", "profile")}
                            onBlur={(e) => handleOnBlur(e, "text", "profile")}
                            ref={e => inputRef.current["familyName"] = e}
                          />
                        </CustomSpacing>
                      </React.Fragment>
                  }
                  <DayMonthYearGroup
                    developmentUrl={window.location.origin + "/able-sprites.svg"}
                    label='Date of birth'
                    helpText="Use the format DD MM YYYY. Enter numbers only."
                    invalidInputText={errors.dob}
                    maxYear={currentYear - 10}
                    minYear={currentYear - 120}
                    required={true}
                    isInvalid={errors.dob ? true : false}
                    externalOnChange={(value) => customDateChange(value, "dob", "profile")}
                    externalOnBlur={(value) => customDateBlur(value, "dob", "profile")}
                    value={dateOfBirth}
                    ref={e => inputRef.current["dob"] = e}
                  />
                </React.Fragment>
            }
            <CustomSpacing top="spacing5x" bottom="spacing5x">
              <RadioGroup
                groupLabel='Choose an ID document to verify'
                name='idType'
                id='idType'
                variant='Comfortable'
                developmentUrl={window.location.origin + '/able-sprites.svg'}
                invalid={errors.idType ? true : false}
                legendRef={radioGroupRef}
                errorText={errors.idType}
              >
                {
                  idSelection.idType && idSelection.idType.length > 0 && idSelection.idType.map((idType, index) => {
                    return (
                      <RadioButton
                        key={index}
                        developmentUrl={window.location.origin + '/able-sprites.svg'}
                        value={idType.value}
                        label={idType.desc}
                        name={idType.value}
                        events={
                          {
                            onChange: e => { handleOnChange(e, "radio", "idType") }
                          }
                        }
                        checked={idType.label === selectIdType ? true : false}
                        ref={(e) => (index==0 ? inputRef.current["idType"] = e:null)}
                      />
                    )
                  })
                }
              </RadioGroup>
              <div ref={idUnavialableRef} role="alert">
                <GlobalBanner pageVerify = {true} />
              </div>
              {
                documentKeys.length > 0 && documentKeys.map((key, index) => {
                  return (
                    <CustomSpacing top="spacing3x" key={index}>
                      {
                        key.type === "select" &&
                        <Select
                          id={key.id}
                          name={key.id}
                          label={key.title}
                          helpText={selectIdType === 'Driver Licence' ? dlHelpText[key.id] : key.helpText}
                          options={key.options}
                          value={documentInputs[key.id]}
                          invalid={errors[key.id] ? true : false}
                          invalidMessage={errors[key.id]}
                          developmentUrl={
                            window.location.origin + "/able-sprites.svg"
                          }
                          events={
                            {
                              onChange: e => { handleOnChange(e, key.type) },
                              onBlur: e => { handleOnBlur(e, key.type) }
                            }
                          }
                          ref={(e) => (inputRef.current[key.id] = e)}
                        />
                      }
                      {
                        key.type === "date" &&
                        <DayMonthYearGroup
                          developmentUrl={window.location.origin + "/able-sprites.svg"}
                          label={key.title}
                          helpText={key.helpText}
                          invalidInputText={errors[key.id]}
                          minYear={expiryDateMinYear}
                          required={true}
                          isInvalid={errors[key.id] ? true : false}
                          externalOnChange={(value) => customDateChange(value, "expiryDate")}
                          externalOnBlur={(value) => customDateBlur(value, "expiryDate")}
                          value={expiryDate}
                          ref={e => inputRef.current[key.id] = e}
                          className={`${disableDate ? 'disableDate' : ''}`}
                        />
                      }
                      {
                        key.type === "text" &&
                        <CustomSpacing>
                          <TextField
                            id={key.id}
                            name={key.id}
                            label={key.title}
                            helpText={selectIdType === 'Driver Licence' ? dlHelpText[key.id] : key.helpText}
                            value={documentInputs[key.id]}
                            invalid={errors[key.id] ? true : false}
                            invalidInputText={errors[key.id]}
                            developmentUrl={window.location.origin + "/able-sprites.svg"}
                            events={
                              {
                                onChange: e => { handleOnChange(e, key.id) },
                                onBlur: e => { handleOnBlur(e, key.id) }
                              }
                            }
                            ref={e => inputRef.current[key.id] = e}
                          />
                        
                        </CustomSpacing>
                      }
                      {key.showCardRef &&
                            <CustomSpacing top="spacing1x">
                              <ActionButton
                                developmentUrl={
                                  window.location.origin + "/able-sprites.svg"
                                }
                                element="button"
                                label="Where to find my ID numbers?"
                                to="/"
                                className="noIcon"
                                type="button"
                                variant="LowEmphasis"
                                onClick={() =>
                                  handleOnClick("find", "Where to find my ID numbers?")
                                }
                                elementRef={e => buttonRef.current["findMyID"] = e}
                              />
                            </CustomSpacing>
                          }
                    </CustomSpacing>
                  )
                })
              }
            </CustomSpacing>
            <TextStyle alias="HeadingC" element="h2">Permission to verify your ID</TextStyle>
            
            <CustomSpacing top="spacing1x">
              <Checkbox
                label="I agree that my identity document details will be checked with the official record holder via third party systems to verify my identity"
                ref={checkboxRef}
                aria-describedby="consent"
                name="consent"
                value="consent"
                events={{
                  onChange: (e)=>handleOnChange(e, "consent")
                }}
                checked={checkbox}
                variant='Comfortable'
                className={checkboxErr ? "checkboxErr" : ""}
              />
              {
                checkboxErr &&
                <CustomSpacing top="spacing1x">
                  <TextStyle element="p" id="consent">
                    <span className="able-common__error">
                    {
                      <svg class="able-icon able-icon--24" role="img" aria-hidden="false" aria-label="Error" focusable="false">
                        <use href={window.location.origin + '/able-sprites.svg#Error'}></use>
                      </svg>
                    }
                      Please provide consent to verify your ID
                    </span>
                  </TextStyle>
                </CustomSpacing>
              }
            </CustomSpacing>
            <CustomSpacing top="spacing3x">
              <InlineLink>
                <TextStyle alias="FinePrintA">
                  Information about how we collect, store, use and share your personal information can be found in our <a href={privacy_link} target="_blank" id="privacyLink" rel="noreferrer" >Privacy Statement</a>.
                </TextStyle>
              </InlineLink>
            </CustomSpacing>

            <CustomSpacing top="spacing5x">
              <ActionButton
                developmentUrl={
                  window.location.origin + "/able-sprites.svg"
                }
                element="button"
                label="Continue"
                to="/"
                type="button"
                variant="HighEmphasis"
                className="fullwidth"
                onClick={() =>
                  handleOnClick("agree", "Continue")
                }
              />
              <CustomSpacing top="spacing5x"></CustomSpacing>
              <ActionButton
                developmentUrl={
                  window.location.origin + "/able-sprites.svg"
                }
                element="button"
                label="Choose another way to verify"
                to="/"
                icon="ChevronLeft"
                type="button"
                variant="LowEmphasis"
                className="fullwidth"
                onClick={() =>
                  handleOnClick("verify", "Choose another way to verify")
                }
              />
            </CustomSpacing>
          </Column>
        </Grid>
      }
    </PageContent>
  );
}
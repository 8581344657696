import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux'
import 'promise-polyfill/src/polyfill';
import "@able/web/dist/able-web.css";
import "@able/react/dist/able-react-without-LG.min.css";
import { store } from "./store";
import App from "./pages/App";
import Loader from './components/Loader';
import PageRestricted from './pages/PageRestricted';
import './assets/cnsb.css';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from "react-oidc-context";
import constants from "../src/helpers/oidcConstants";
import { getSession, setSession } from "./helpers"

const qs = window.location.search;
if (qs && qs.trim()!=="") {
  const goToUrlRegex = /^https:\x2f\x2f([a-z0-9-]+\.)*(telstra\.com|telstra\.com\.au|boost\.com\.au|jbhifi\.com\.au)(\x2f[\x21\x23-\x3b\x3d\x3f-\x5b\x5d\x5f\x61-\x7b\x7d\x7e]*)?$/;
  let params = new URLSearchParams(qs);
  if (params.get("token")) {
    setSession("user_token", params.get("token"))
  }
  if (params.get("gotoUrl") && goToUrlRegex.test(params.get("gotoUrl"))) {
    setSession("gotoUrl", params.get("gotoUrl"))
  }
}

const oidcConfig = {
  authority: constants.stsAuthority,
  client_id: constants.clientId,
  redirect_uri: constants.clientRoot + "signin-callback.html",
  automaticSilentRenew: true,
  prompt: 'none',
  scope: constants.clientScope
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Suspense fallback={<Loader />}>
        {
          (getSession("user_token") && getSession("gotoUrl")) ?
          <AuthProvider {...oidcConfig}>
            <App />
          </AuthProvider>
          : <PageRestricted/>
        }
      </Suspense>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
